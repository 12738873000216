import React from "react";

export const Services = (props) => {
  return (
    <div id="courses" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>What will you learn?</h2>
          <p className="col-xs-12 col-md-8">
          You will learn to follow the same steps for emergency care as medical emergency professionals and practise applying skills in a nonstressful learning environment. You will learn the skills through a combination of knowledge development, skills development and realistic scenario practice. The courses are supported with high quality videos and manuals, but the focus in all EFR courses is very much on hands-on practice. EFR courses follow the latest guidelines set by International Liaison Committee on Resuscitation (ILCOR).
          </p>
          <div className="col-xs-12 col-md-4 section-title">
          <img src="img/EFR.jpg" alt="" />{" "}
          </div>

        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3><strong>{d.name}</strong></h3>
                    <div dangerouslySetInnerHTML={{ __html: d.text }} />
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};